import * as Sentry from "@sentry/browser";
import { SENTRY } from "./utils/constants";

Sentry.captureEvent({
  level: SENTRY.LEVEL.Info,
  message: "404 page hit",
});

const backLink = document.getElementById("back-link");

// Replace href with referral URL
backLink.setAttribute("href", document.referrer);

backLink.addEventListener("click", (e) => {
  e.preventDefault();

  // Nav to previous page
  window.history.back();
});
